<template>
  <app-list-view
    server-side
    show-group-by
    app="authentication"
    model="user"
    api-url="auth/user/"
    :title="$t('menu.user')"
    :createTo="{ name: 'userCreate' }"
    :editTo="{ name: 'userEdit' }"
    :headers="headers"
  >
    <template v-slot:item.is_active="{ item }">
      <v-icon :color="item.is_active ? 'green' : 'grey lighten-2'">
        mdi-check
      </v-icon>
    </template>
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'
import { mapGetters } from 'vuex'

export default {
  name: 'userList',
  components: {
    AppListView
  },
  computed: {
    ...mapGetters(['user']),
    headers() {
      const headers = [
        { text: this.$t('fields.username'), value: 'username' },
        { text: this.$t('fields.firstName'), value: 'contact_id.first_name' },
        { text: this.$t('fields.lastName'), value: 'contact_id.last_name' },
        {
          text: this.$t('fields.accessRight'),
          value: 'group_id.name',
          filterName: 'groups__name'
        },
        { text: this.$t('fields.state'), value: 'state' }
      ]
      if (this.user && this.user.is_superuser) {
        headers.push({
          text: this.$t('fields.customerCompany'),
          value: 'customer_company_id.name'
        })
      }
      headers.push(
        { text: this.$t('fields.startDate'), value: 'start_date' },
        { text: this.$t('fields.endDate'), value: 'end_date' },
        {
          text: this.$t('fields.active'),
          value: 'is_active',
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      )
      return headers
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'user'
    })
  }
}
</script>
